<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1 v-html="$t('login_to_healios')"></h1>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <b-form-group :label="$t('email')" label-for="email" class="mb-0">
            <b-form-input
              v-model="email"
              id="email"
              :state="emailStatus"
              aria-describedby="email-live-feedback"
              :placeholder="$t('email_tip')"
              maxLength="150"
            ></b-form-input>

            <b-form-invalid-feedback id="email-live-feedback">{{
              $t('error_email_not_valid_fe')
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-invalid-feedback id="input-live-feedback">
            {{ $t('error_email_not_valid_fe') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <label for="password">{{ $t('password') }}</label>
          <password-input
            backgroundColor="transparent"
            v-model="password"
            :placeholder="$t('password_tip')"
          />
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-5">
        <b-col class="d-flex flex-row justify-content-end align-content-center">
          <div class="link">
            <router-link :to="{ name: 'password-reset' }">
              {{ $t('password_forgot') }}
            </router-link>
          </div>
        </b-col>
      </b-row>

      <b-row
        v-if="loginError && validForm"
        class="mb-3"
        data-testid="login-error"
      >
        <b-col class="login-error"> {{ $t(loginError) }} </b-col>
      </b-row>

      <b-row>
        <b-col>
          <base-button
            type="primary"
            rounded
            data-testid="login-button"
            :disabled="disableButton"
            @click="handleLogin"
            >{{ $t('log_in') }}
            <b-spinner small v-if="loading"></b-spinner>
            <arrow-right v-else />
          </base-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ArrowRight from './Icons/ArrowRight';
import PasswordInput from './PasswordInput.vue';

export default {
  name: 'LoginForm',
  components: {
    PasswordInput,
    ArrowRight,
  },
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      loginError: null,
    };
  },
  computed: {
    disableButton() {
      return !this.validForm || this.loading;
    },
    validEmail() {
      return (
        !!this.email &&
        /^[a-zA-Z+0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email)
      );
    },
    emailStatus() {
      let value = this.email === null ? null : this.validEmail;

      if (value === false) {
        this.editLoginError(null);
      }

      return value;
    },
    validForm() {
      return this.validEmail && !!this.password && this.passwordIsValid;
    },

    passwordIsValid() {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!%^&*()])[0-9a-zA-Z@#$!%^&*()]{8,}$/.test(
        this.password
      );
    },
  },
  methods: {
    editLoginError(value) {
      this.loginError = value;
    },
    async handleLogin() {
      this.loading = true;
      this.editLoginError(null);

      try {
        this.$store
          .dispatch('auth/GET_USER_SESSION', {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.$router.push({ path: '/' });
            localStorage.setItem('user_email', this.email);
          })
          .catch((error) => {
            this.editLoginError(
              error?.data?.msg || this.$t('error_something_went_wrong')
            );
          });
      } catch (error) {
        this.editLoginError(
          error?.data?.msg || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  background-color: transparent;
}
.link a {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.login-error {
  font-size: 80%;
  color: #fb6340;
}
</style>
