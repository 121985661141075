<template>
  <div>
    <login-form />
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm';
import { mapGetters } from 'vuex';

export default {
  name: 'login',
  components: {
    LoginForm,
  },
  created() {
    if (this.token) {
      this.logged = true;
    }
  },
  data() {
    return {
      logged: false,
      redirect: null,
    };
  },
  computed: {
    ...mapGetters(['token']),
  },
  methods: {
    redirectToView() {
      this.$router.push({ path: this.redirect || '/' });
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped></style>
