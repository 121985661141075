<template>
  <b-row>
    <b-col v-if="updated">
      <b-row>
        <b-col>
          <h1
            data-testid="success-message"
            v-html="$t('password_has_changed_header')"
          >
            <i class="far fa-check-circle ml-2"></i>
          </h1>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col class="description" data-testid="link-message">
          {{ $t('password_has_changed_detail') }}
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col>
          <router-link
            :to="{ name: 'login' }"
            tag="button"
            class="btn btn-primary rounded-pill"
            type="button"
          >
            {{ $t('login_with_new_password') }}
          </router-link>
        </b-col>
      </b-row>
    </b-col>

    <b-col v-else>
      <b-row>
        <b-col>
          <h1 v-html="$t('password_reset')"></h1>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <label for="password">{{ $t('password_new') }}</label>
          <password-input
            v-model="newPassword"
            id="password"
            :placeholder="$t('password_new_tip')"
          />
        </b-col>
      </b-row>

      <b-row v-if="newPassword !== null && !passwordIsStrong" class="mt-2">
        <b-col
          class="error-message line-break"
          data-testid="nonStrong-error-message"
        >
          {{ $t('password_change_validation_info') }}
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <label for="repeat-password">{{ $t('password_new_repeat') }}</label>
          <password-input
            v-model="confirmNewPassword"
            id="repeat-password"
            :placeholder="$t('password_new_repeat_tip')"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="
          newPassword !== null &&
          confirmNewPassword !== null &&
          !passwordsMatches
        "
        class="mt-2"
      >
        <b-col class="error-message" data-testid="mismatch-message">
          {{ $t('error_password_not_match') }}
        </b-col>
      </b-row>

      <b-row v-if="responseError" class="mt-2">
        <b-col class="error-message" data-testid="response-error-message">
          {{ $t(responseError) }}
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col>
          <base-button
            type="primary"
            :disabled="disableButton"
            data-testid="update-button"
            @click="update"
            rounded
            >{{ $t('password_reset') }}
          </base-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import PasswordInput from '@/components/PasswordInput';
import { passwordUpdate } from '@/api/auth';

export default {
  name: 'PasswordUpdate',
  components: {
    PasswordInput,
  },
  created() {
    this.hash = this.$route.query.hash;
  },
  data() {
    return {
      hash: null,
      newPassword: null,
      confirmNewPassword: null,
      updated: false,
      loading: false,
      responseError: '',
    };
  },
  computed: {
    passwordIsStrong() {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!%^&*()])[0-9a-zA-Z@#$!%^&*()]{8,}$/.test(
        this.newPassword
      );
    },
    passwordsMatches() {
      return this.newPassword === this.confirmNewPassword;
    },
    validPassword() {
      return (
        this.newPassword &&
        this.confirmNewPassword &&
        this.passwordIsStrong &&
        this.passwordsMatches
      );
    },
    disableButton() {
      return !this.hash || !this.validPassword || this.loading;
    },
  },
  methods: {
    async update() {
      this.loading = true;
      this.responseError = '';
      try {
        await passwordUpdate({
          hash: this.hash,
          newPassword: this.newPassword,
          confirmNewPassword: this.confirmNewPassword,
        });
        this.updated = true;
      } catch (error) {
        this.responseError =
          error?.data?.msg || this.$t('error_something_went_wrong');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.description {
  color: #979797;
}

.error-message {
  font-size: 14px;
  color: #d15259;
}
</style>
