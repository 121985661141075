<template>
  <b-input-group class="input-container">
    <b-form-input
      :class="{ 'bg-transparent': backgroundColor === 'transparent' }"
      data-testid="password-input"
      :type="type"
      v-model="password"
      :placeholder="placeholder"
      @input="input"
    />
    <template #append>
      <b-input-group-text>
        <i
          v-if="type === 'password'"
          class="far fa-eye eye-icon"
          @click="type = 'text'"
        />
        <i
          v-else-if="type === 'text'"
          class="far fa-eye-slash eye-icon"
          @click="type = 'password'"
        />
      </b-input-group-text>
    </template>
  </b-input-group>
</template>

<script>
export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      password: null,
      type: 'password',
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.password = null;
      }
    },
  },

  methods: {
    input() {
      this.$emit('input', this.password);
    },
  },
};
</script>

<style scoped>
.input-container {
  position: relative;
  width: 100%;
}

.eye-icon {
  color: #adb2ba;
  cursor: pointer;
  z-index: 10;
}

.input-group-text {
  border: none;
  background: transparent;
  border-bottom: 1px solid #cad1d7;
  border-radius: 0px;
}

.fa-eye-slash {
  right: 11px !important;
}
.bg-transparent {
  background-color: transparent;
}
</style>
