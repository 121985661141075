<template>
  <div class="m-5 bg-white">
    <h1 class="mb-0 p-4 border-bottom topbar-title flex-grow-1">
      {{ $t('password_change') }}
    </h1>

    <b-row class="mx-2 pb-5 pt-4">
      <b-col md="6">
        <label> {{ $t('password_current') }} * </label>
        <password-input v-model="password.currentPassword" />

        <label> {{ $t('password_new') }} * </label>

        <password-input v-model="password.newPassword" />

        <label
          class="error-message line-break"
          data-testid="nonStrong-error-message"
          v-if="!!password.newPassword && !newPasswordIsStrong"
        >
          {{ $t('password_change_validation_info') }}
        </label>
        <label> {{ $t('password_new_repeat') }} * </label>
        <password-input v-model="password.confirmNewPassword" />

        <div
          class="error-message"
          data-testid="mismatch-message"
          v-if="!!password.confirmNewPassword && !newPasswordsMatches"
        >
          {{ $t('error_password_not_match') }}
        </div>

        <p class="mt-5 line-break">
          {{ $t('password_change_validation_info') }}
        </p>

        <base-button
          type="primary"
          class="mt-5"
          :disabled="!validPasswordForm || loading"
          @click="changePassword"
          data-testid="change-password-button"
          >{{ $t('password_change') }}
          <b-spinner small v-if="loading"></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { changePassword } from '@/api/experts';
import PasswordInput from '@/components/PasswordInput';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ChangePassword',
  components: {
    PasswordInput,
  },
  created() {
    this.setBreadCrumbData({
      'change-password': [
        {
          text: this.$t('password_change'),
          active: true,
        },
      ],
    });
  },
  data() {
    return {
      redirectUrl: '',
      redirectUrlParam: 'hash',
      email: null,
      sent: false,
      loading: false,
      requestError: null,
      password: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
    };
  },
  computed: {
    ...mapState({
      expertId: (state) => state.auth.expertId,
    }),
    newPasswordIsStrong() {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!%^&*()])[0-9a-zA-Z@#$!%^&*()]{8,}$/.test(
        this.password.newPassword
      );
    },
    newPasswordsMatches() {
      return this.password.newPassword === this.password.confirmNewPassword;
    },
    validPasswordForm() {
      return (
        !!this.password.currentPassword &&
        !!this.password.newPassword &&
        !!this.password.confirmNewPassword &&
        this.newPasswordIsStrong &&
        this.newPasswordsMatches
      );
    },
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/dispatchFetchExpertNotifications',
    }),
    async changePassword() {
      this.loading = true;
      try {
        await changePassword(this.$store.state.auth.expertId, this.password);
        this.$toast.info(
          this.$t('notification_create_password_changed_message')
        );
        this.getNotifications();

        this.password = {
          currentPassword: null,
          newPassword: null,
          confirmNewPassword: null,
        };
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
      }
    },
    async getNotifications() {
      await this.fetchNotifications(this.expertId);
    },
  },
};
</script>

<style scoped>
.error-message {
  font-size: 14px;
  color: #d15259;
}

.link a {
  font-weight: 500;
  font-size: 16px;
  color: #4dc185;
  cursor: pointer;
}

.description {
  color: #979797;
}

h1 i {
  color: #4dc185;
}

.request-error {
  font-size: 80%;
  color: #fb6340;
}
</style>
